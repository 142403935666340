const constants = {
  SET_PAGE_DIMENSION: "SET_PAGE_DIMENSION",
  REMOVE_PAGE_DIMENSION: "REMOVE_PAGE_DIMENSION",
  SET_INTRO: "SET_INTRO",
  REMOVE_INTRO: "REMOVE_INTRO",
  SET_LINE_IMAGE: "SET_LINE_IMAGE",
  REMOVE_LINE_IMAGE: "REMOVE_LINE_IMAGE",
  SET_HEADER: "SET_HEADER",
  CLEAR_HEADER: "CLEAR_HEADER",
  ADD_AD: "ADD_AD",
  REMOVE_AD: "REMOVE_AD",
  SET_ADS: "SET_ADS",
  ADD_QUESTION: "ADD_QUESTION",
  SET_QUESTIONS: "SET_QUESTIONS",
  REMOVE_QUESTION: "REMOVE_QUESTION",
  UPDATE_QUESTION: "UPDATE_QUESTION",
  ADD_CB_QUESTION: "ADD_CB_QUESTION",
  SET_CB_QUESTIONS: "SET_CB_QUESTIONS",
  REMOVE_CB_QUESTION: "REMOVE_CB_QUESTION",
  UPDATE_CB_QUESTION: "UPDATE_CB_QUESTION",
  ADD_CATEGORY: "ADD_CATEGORY",
  SET_CATEGORY: "SET_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  REMOVE_CATEGORY: "REMOVE_CATEGORY",
  ADD_DOMAIN_RESOURCE: "ADD_DOMAIN_RESOURCE",
  SET_DOMAIN_RESOURCE: "SET_DOMAIN_RESOURCE",
  MERGE_DOMAIN_RESOURCE: "MERGE_DOMAIN_RESOURCE",
  REMOVE_DOMAIN_RESOURCE: "REMOVE_DOMAIN_RESOURCE",
  USER_LOGOUT: "USER_LOGOUT",
  SET_API_KEY: "SET_API_KEY",
  CLEAR_API_KEY: "CLEAR_API_KEY",
  SET_ADMIN_API_KEY: "SET_ADMIN_API_KEY",
  CLEAR_ADMIN_API_KEY: "CLEAR_ADMIN_API_KEY",
  ADD_CAMPAIGN: "ADD_CAMPAIGN",
  REMOVE_CAMPAIGN: "REMOVE_CAMPAIGN",
  SET_CAMPAIGN: "SET_CAMPAIGN",
  SET_USER: "SET_USER",
  REMOVE_USER: "REMOVE_USER",
  SET_ALL_USERS: "SET_ALL_USERS",
  ADD_SINGLE_USER: "ADD_SINGLE_USER",
  REMOVE_ALL_USERS: "SET_ALL_USERS",
  REMOVE_SINGLE_USER: "REMOVE_SINGLE_USER",
  UPDATE_ALL_USERS: "UPDATE_ALL_USERS",
  ADD_ERROR: "ADD_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR"
};

export default constants;
